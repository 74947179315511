/**
 * Nosy Content Item Image
 *
 * @export ContentImage
 */

import { Container, Flex, useBreakpointValue, useToken } from '@chakra-ui/react'
import React from 'react'
import Image from '../image/image'

const ContentImage = ( { 
    contentIndex = null,
    contentAnchor = {},
    contentImageMedia = {}, 
    contentImageBackgroundColor = {} 
} ) => {
    
    // Get default fallback colors from theme
    const [ brandGrayscaleBlack ] = useToken( 'colors', ['brand_grayscale.black'] )
    
    // Destructure color values, and assign fallback
    const { 
        hex: contentImageBackgroundColorHex = brandGrayscaleBlack 
    } = contentImageBackgroundColor

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    const imageWidths = useBreakpointValue( {
        base: 447, 
        sm: 735,
        md: 960,
        lg: 1120
    } )
    
    return (
        <Flex
            id={anchor} 
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}
            bg={contentImageBackgroundColorHex}
            justifyContent='center'
            alignItems='center'>
            <Container 
                maxWidth='6xl' 
                mx='auto'>
                {Image &&
                    <Image
                        imageData={contentImageMedia}
                        width={imageWidths}
                        objectFit='contain'
                        maxHeight={[ '2xs', 'sm', 'md', 'full' ]}
                        sx={{ 
                            width: 'full'
                        }} />
                }
            </Container>
        </Flex>
    )
}

export default ContentImage
