/**
 * Nosy Content Item Long Form
 *
 * @export ContentLongForm
 */
import { Box, Flex, Heading, Stack, useToken } from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import Color from 'color'
import React from 'react'
import { contentBuilderBodySerializer } from '../../serializers'

const ContentLongForm = ( {
    contentIndex = null,
    contentAnchor = {},
    longFormTitle = null,
    longFormContent = [],
    longFormBackgroundColor = {}
} ) => {

    // Get default fallback colors from theme
    const [ brandGrayscaleBlack ] = useToken( 
        'colors', [
            'brand_grayscale.black'
        ]
    )

    const { 
        hex: longFormBackgroundColorHex = brandGrayscaleBlack 
    } = longFormBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( longFormBackgroundColorHex ).isDark() 
        ? 'gray.200' 
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor} 
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={longFormBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {longFormTitle && (
                    <Heading
                        as='h1'
                        size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'
                        mb={12}>
                        {longFormTitle}
                    </Heading>
                )}
            </Stack>
            <Box 
                mt={4} 
                px={[6, null, 0]}
                maxWidth='2xl'
                mx='auto'
                color={textColor}>
                <PortableText
                    value={longFormContent}
                    components={contentBuilderBodySerializer} />
            </Box>
        </Flex>
    )
}

export default ContentLongForm