/**
 * Nosy Content Item IFrame
 *
 * @export ContentIframe
 */

import {
    Alert,
    AlertDescription,
    AlertTitle,
    AspectRatio,
    Box,
    Container,
    Flex,
    Heading,
    Stack,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import capitalize from 'capitalize'
import Color from 'color'
import React from 'react'
import { contentBuilderBodySerializer } from '../../serializers'
import { createAspectRatio } from '../../utils/helpers'

//#region OneColumnFeature
const OneColumnFeature = ({
    contentIndex = null,
    contentAnchor = {},
    iframeTitle = '',
    iframeUrl = '',
    iframeAspectRatio = '',
    iframeBackgroundColor = {}
}) => {
    // Setup loading state
    // const [ loading, setLoading ] = useState( true )

    // Get default fallback colors from theme
    const [brandGrayscaleBlack] = useToken('colors', ['brand_grayscale.black'])

    // Destructure color values, and assign fallback
    const { hex: iframeBackgroundColorHex = brandGrayscaleBlack } =
        iframeBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color(iframeBackgroundColorHex).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={iframeBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                px={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {iframeTitle && (
                    <Heading
                        as='h1'
                        size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'
                        mb={12}>
                        {iframeTitle}
                    </Heading>
                )}

                {iframeUrl && (
                    <AspectRatio ratio={createAspectRatio(iframeAspectRatio)}>
                        <Box
                            as='iframe'
                            src={iframeUrl}
                            width='100%'
                            height='100%'
                        />
                    </AspectRatio>
                )}
            </Stack>
        </Flex>
    )
}
//#endregion

//#region TwoColumnText
const TwoColumnText = ({
    contentIndex = null,
    contentAnchor = {},
    contentReverseLayout = false,
    iframeTitle = '',
    iframeText = [],
    iframeUrl = '',
    iframeAspectRatio = '',
    iframeBackgroundColor = {}
}) => {
    // Get default fallback colors from theme
    const [brandGrayscaleBlack] = useToken('colors', ['brand_grayscale.black'])

    // Destructure color values, and assign fallback
    const { hex: iframeBackgroundColorHex = brandGrayscaleBlack } =
        iframeBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color(iframeBackgroundColorHex).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}
            bg={iframeBackgroundColorHex}
            justifyContent='center'
            alignItems='center'>
            <Flex
                px={[6, null, 8]}
                direction={[
                    contentReverseLayout ? 'column-reverse' : 'column',
                    null,
                    contentReverseLayout ? 'row-reverse' : 'row'
                ]}
                width='full'
                maxWidth={['xl', null, '3xl', '5xl', '6xl']}
                justifyContent='space-between'
                alignItems='flex-start'>
                <Stack width={['full', null, '30%']}>
                    <Heading
                        as='h2'
                        color={textColor}
                        fontSize={['3xl', '4xl']}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        lineHeight='1'
                        letterSpacing='tight'
                        mb={6}>
                        {iframeTitle}
                    </Heading>
                    <Box mt={4} fontSize='lg' color={textColor}>
                        <PortableText
                            value={iframeText}
                            components={contentBuilderBodySerializer}
                        />
                    </Box>
                </Stack>
                <Stack width={['full', null, '60%']} py={[12, 6, 3, 0]}>
                    {iframeUrl && (
                        <AspectRatio
                            ratio={createAspectRatio(iframeAspectRatio)}>
                            <Box
                                as='iframe'
                                src={iframeUrl}
                                width='full'
                                height='full'
                            />
                        </AspectRatio>
                    )}
                </Stack>
            </Flex>
        </Flex>
    )
}
//#endregion

const ContentIframe = ({ iframeType = '', ...rest }) => {
    // Create lookup map to match video type to a component
    const iframeTypes = {
        'one-column-feature': OneColumnFeature,
        'two-column-text': TwoColumnText
    }

    if (typeof iframeTypes[iframeType] === 'undefined') {
        return (
            <Container maxWidth='full' centerContent>
                <Alert
                    status='error'
                    variant='left-accent'
                    borderRadius='sm'
                    my={10}
                    maxWidth='2xl'>
                    <AlertTitle mr={2}>Iframe Component not found</AlertTitle>
                    <AlertDescription>
                        Component <strong>"{capitalize(iframeType)}"</strong>{' '}
                        not found 😕
                    </AlertDescription>
                </Alert>
            </Container>
        )
        // ... Else create IFrame Component and pass through props
    } else {
        const IframeComponent = iframeTypes[iframeType]
        return <IframeComponent {...rest} />
    }
}

export default ContentIframe
