/**
 * Nosy Content Item Hero
 *
 * @export ContentHero
 */

import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box,
    Button,
    Center, Container, Flex,
    Heading,
    Stack,
    useBreakpointValue,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import capitalize from 'capitalize'
import Color from 'color'
import React from 'react'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { contentBuilderBodySerializer } from '../../serializers'
import Image from '../image/image'
import Link from '../link/link'

// Hero type variants

//#region HeroOneColumnImage
const HeroOneColumnImage = ( {
    contentIndex = null,
    contentAnchor = {},
    heroBackgroundColor = {},
    heroImage = {},
    heroTitle = '',
    heroStrapline = [],
    heroCtaButtons = []
} ) => {
    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values
    const { 
        hex: heroBackgroundColorHex = brandGrayscaleBlack 
    } = heroBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( heroBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Create dynamic image sizing relative to viewport/device
    const imageWidths = useBreakpointValue( {
        base: 431, 
        sm: 528,
        md: 927,
        lg: 960
    } )

    return (
        <Box 
            id={anchor} 
            as='section' 
            bg={heroBackgroundColorHex} 
            color='white'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Box
                maxWidth={{ base: 'xl', md: '5xl' }}
                mx='auto'
                px={['6', null, '8']}>
                <Box textAlign='center'>
                    <Heading 
                        as='h1'
                        maxWidth='5xl'
                        fontSize={[ '3xl', '4xl', '6xl','8xl' ]}
                        mx='auto'
                        fontWeight='extrabold'
                        color={textColor}
                        lineHeight='1.2'
                        letterSpacing='tight'>
                        {heroTitle}
                    </Heading>
                    <Box
                        my={8}
                        fontSize='xl'
                        fontWeight='medium'
                        color={textColor}
                        maxWidth='xl'
                        mx='auto'>
                        <PortableText
                            value={heroStrapline}
                            components={contentBuilderBodySerializer} />
                    </Box>
                </Box>
                <Stack
                    justify='center'
                    direction={{ base: 'column', md: 'row' }}
                    mt={10}
                    mb={20}
                    spacing='4'>
                    {heroCtaButtons && heroCtaButtons.map( ( node ) => {
                        const {
                            _key: key = '',
                            buttonLabel = '',
                            buttonColor = {},
                            customLink = false,
                            destinationUrl = '',
                            destinationRef = {},
                            buttonNewTab = false
                        } = node

                        // Destructure color values
                        const { hex: buttonColorHex = brandColor } = buttonColor

                        // Resolve link destination urls
                        let destination
                        if ( customLink ) {
                            destination = destinationUrl
                        } else {
                            destination = ( destinationRef.slug ? `${siteUrl}/${destinationRef.slug.current}/` : '#' )
                        }

                        return (
                            <Button
                                key={key}
                                as={Link}
                                to={destination}
                                variant='brand-outline'
                                target={buttonNewTab ? '_blank' : null}
                                size='lg'
                                px={8}
                                fontWeight='bold'
                                fontSize='md'
                                borderColor={buttonColorHex}
                                _hover={{
                                    borderColor: Color( buttonColorHex )
                                        .darken( 0.125 )
                                        .hex(),
                                    backgroundColor: Color( buttonColorHex )
                                        .darken( 0.125 )
                                        .hex()
                                }}>
                                {buttonLabel}
                            </Button>
                        )
                    } )}
                </Stack>

                <Box>
                    <Image 
                        imageData={heroImage} 
                        // width={960}
                        width={imageWidths} />
                </Box>
            </Box>
        </Box>
    )
}
//#endregion

//#region HeroOneColumnBackgroundImage
const HeroOneColumnBackgroundImage = ( {
    contentIndex = null,
    contentAnchor = {},
    heroBackgroundColor = {},
    heroImage = {},
    heroTitle = '',
    heroStrapline = [],
    heroCtaButtons = []
} ) => {
    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values and fallback to theme values
    const { 
        hex: heroBackgroundColorHex = brandGrayscaleBlack 
    } = heroBackgroundColor

    // Specify text color
    const textColor = 'gray.200'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Create dynamic image sizing relative to viewport/device
    const imageWidths = useBreakpointValue( {
        base: 800, 
        sm: 800,
        md: 1000,
        lg: 1300,
        xl: 2000
    } )

    return (
        <Flex
            id={anchor}
            as='section'
            justifyContent='center'
            alignItems='center'
            bg={heroBackgroundColorHex}
            position='relative'
            minHeight={['100vh', null, '800px']}
            mt={contentIndex === 0 ? -40 : 12}
            mb={12}
            _after={{
                content: '""',
                display: 'block',
                width: 'full',
                height: 'full', 
                bg: 'blackAlpha.700',
                position: 'absolute',
                inset: 0,
                zIndex: 1,
            }}>
            <Image
                imageData={heroImage}
                position='absolute'
                width={imageWidths}
                sx={{
                    width: 'full',
                    height: ['100vh', null, '800px']
                }} />
            <Flex
                width='full'
                height='full'
                justifyContent='center'
                alignItems='center'
                mx='auto'
                px={{
                    base: '6',
                    md: '8'
                }}
                zIndex={2}
                position='relative'>
                <Center
                    flexDirection='column'
                    textAlign='center'
                    color='white'
                    h='full'>
                    <Heading 
                        as='h1'
                        maxWidth='5xl'
                        fontSize={[ '3xl', '4xl', '6xl','8xl' ]}
                        mx='auto'
                        fontWeight='extrabold'
                        color={textColor}
                        lineHeight='1'
                        letterSpacing='tight'>
                        {heroTitle}
                    </Heading>
                    <Box
                        mt={10}
                        fontSize='lg'
                        fontWeight='medium'
                        color={textColor}
                        maxWidth='4xl'>
                        <PortableText
                            value={heroStrapline}
                            components={contentBuilderBodySerializer} />
                    </Box>
                    {heroCtaButtons && (
                        <Stack
                            direction={{
                                base: 'column',
                                sm: 'row'
                            }}
                            mt='10'
                            justifyContent='flex-start'
                            mx='auto'
                            spacing={{
                                base: '3',
                                md: '6'
                            }}>
                            {heroCtaButtons.map( ( node ) => {
                                const {
                                    _key: key = '',
                                    buttonLabel = '',
                                    buttonColor = {},
                                    customLink = false,
                                    destinationUrl = '',
                                    destinationRef = {},
                                    buttonNewTab = false
                                } = node

                                // Destructure color values
                                const { hex: buttonColorHex = brandColor } = buttonColor

                                // Resolve link destination urls
                                let destination
                                if ( customLink ) {
                                    destination = destinationUrl
                                } else {
                                    destination = ( destinationRef.slug ? `${siteUrl}/${destinationRef.slug.current}/` : '#' )
                                }

                                return (
                                    <Button
                                        key={key}
                                        as={Link}
                                        to={destination}
                                        variant='brand-outline'
                                        target={buttonNewTab ? '_blank' : null}
                                        size='lg'
                                        px={8}
                                        fontWeight='bold'
                                        fontSize='md'
                                        borderColor={buttonColorHex}
                                        _hover={{
                                            borderColor: Color( buttonColorHex )
                                                .darken( 0.125 )
                                                .hex(),
                                            backgroundColor: Color( buttonColorHex )
                                                .darken( 0.125 )
                                                .hex()
                                        }}>
                                        {buttonLabel}
                                    </Button>
                                )
                            } )}
                        </Stack> )}
                </Center>
            </Flex>
        </Flex>
    )
}
//#endregion

//#region HeroTwoColumnImage
const HeroTwoColumnImage = ( {
    contentIndex = null,
    contentAnchor = {},
    contentReverseLayout = false,
    heroBackgroundColor = {},
    heroImage = {},
    heroTitle = '',
    heroStrapline = [],
    heroCtaButtons = []
} ) => {
    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values
    const { 
        hex: heroBackgroundColorHex = brandGrayscaleBlack 
    } = heroBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( heroBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Create dynamic image sizing relative to viewport/device
    const imageWidths = useBreakpointValue( {
        base: 431, 
        sm: 528,
        md: 704,
        lg: 800
    } )

    return (
        <Flex
            id={anchor}
            as='section'
            bg={heroBackgroundColorHex}
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}
            overflow='hidden'>
            <Box
                maxWidth={{
                    base: 'xl',
                    md: '6xl'
                }}
                mx='auto'
                px={{
                    base: '6',
                    md: '8'
                }}>
                <Flex
                    direction={[
                        ( contentReverseLayout ? 'column-reverse' : 'column' ),
                        null, 
                        ( contentReverseLayout ? 'row-reverse' : 'row' )
                    ]}
                    justifyContent='space-between'
                    alignItems='flex-start'>
                    <Box
                        flex='1'
                        pr={( contentReverseLayout ? 0 : [0, 6, 8] )}
                        pl={( contentReverseLayout ? [0, 6, 8] : 0 )}
                        maxWidth={{
                            lg: '45%'
                        }}
                        pt={6}>
                        <Heading 
                            as='h1'
                            maxWidth='5xl'
                            fontSize={[ '3xl', '4xl', '6xl' ]}
                            mx='auto'
                            mt='8'
                            fontWeight='extrabold'
                            color={textColor}
                            lineHeight='1.2'
                            letterSpacing='tight'>
                            {heroTitle}
                        </Heading>
                        <Box
                            my={8}
                            fontSize='lg'
                            fontWeight='medium'
                            color={textColor}
                            maxWidth='4xl'>
                            <PortableText
                                value={heroStrapline}
                                components={contentBuilderBodySerializer} />
                        </Box>
                        {heroCtaButtons &&
                            <Stack
                                direction={{
                                    base: 'column',
                                    sm: 'row'
                                }}
                                mt='10'
                                justifyContent='flex-start'
                                mx='auto'
                                spacing={{
                                    base: '3',
                                    md: '6'
                                }}>
                                {heroCtaButtons.map( ( node ) => {
                                    const {
                                        _key: key = '',
                                        buttonLabel = '',
                                        buttonColor = {},
                                        customLink = false,
                                        destinationUrl = '',
                                        destinationRef = {},
                                        buttonNewTab = false
                                    } = node
    
                                    // Destructure color values
                                    const { hex: buttonColorHex = brandColor } = buttonColor
    
                                    // Resolve link destination urls
                                    let destination
                                    if ( customLink ) {
                                        destination = destinationUrl
                                    } else {
                                        destination = ( destinationRef.slug ? `${siteUrl}/${destinationRef.slug.current}/` : '#' )
                                    }
    
                                    return (
                                        <Button
                                            key={key}
                                            as={Link}
                                            to={destination}
                                            variant='brand-outline'
                                            target={buttonNewTab ? '_blank' : null}
                                            size='lg'
                                            px={8}
                                            fontWeight='bold'
                                            fontSize='md'
                                            borderColor={buttonColorHex}
                                            _hover={{
                                                borderColor: Color( buttonColorHex )
                                                    .darken( 0.125 )
                                                    .hex(),
                                                backgroundColor: Color( buttonColorHex )
                                                    .darken( 0.125 )
                                                    .hex()
                                            }}>
                                            {buttonLabel}
                                        </Button>
                                    )
                                } )}
                            </Stack>
                        }
                    </Box>
                    <Image
                        imageData={heroImage}
                        mt={[ 10 ]}
                        width={imageWidths}
                        marginStart={[ 0, null, ( !contentReverseLayout ? null : '-16rem' )]}
                        marginEnd={[0, null, ( contentReverseLayout ? null : '-16rem' )]}
                        w='50rem' />
                </Flex>
            </Box>
        </Flex>
    )
}
//#endregion

const ContentHero = ( { heroType = '', ...rest } ) => {
    // Create lookup map to match hero type to a component
    const heroTypes = {
        // Hero 1 col with image below
        'one-column-image': HeroOneColumnImage,
        // Hero with one col & background image
        'one-column-background-image': HeroOneColumnBackgroundImage,
        // Hero with 2 col and image
        'two-column-image': HeroTwoColumnImage
    }

    if ( typeof heroTypes[heroType] === 'undefined' ) {
        return (
            <Container maxWidth='full' centerContent>
                <Alert status='error' variant='left-accent' borderRadius='sm' my={10} maxWidth='2xl'>
                    <AlertTitle mr={2}>Hero Component not found</AlertTitle>
                    <AlertDescription>
                    Component <strong>"{capitalize( heroType )}"</strong> not
                    found 😕
                    </AlertDescription>
                </Alert>
            </Container>
        )
        // ... Else create Hero Component and pass through props
    } else {
        const HeroComponent = heroTypes[heroType]
        return <HeroComponent {...rest} />
    }
}

export default ContentHero
