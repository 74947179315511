/**
 * Nosy Content Item Tabs
 *
 * @export ContentTabs
 */
import {
    Box,
    Container,
    Flex,
    Heading,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import Color from 'color'
import React from 'react'
import { contentBuilderBodySerializer } from '../../serializers'

const ContentTabs = ( {
    contentIndex = null,
    contentAnchor = {},
    tabsTitle = null,
    tabsItems = [],
    tabsBackgroundColor = {}
} ) => {
    // Get default fallback colors from theme
    const [brandGrayscaleBlack] = useToken( 'colors', ['brand_grayscale.black'] )

    const { hex: tabsBackgroundColorHex = brandGrayscaleBlack } =
        tabsBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( tabsBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={tabsBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {tabsTitle && (
                    <Heading
                        as='h1'
                        size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'
                        mb={12}>
                        {tabsTitle}
                    </Heading>
                )}
            </Stack>
            {tabsItems && (
                <Container maxWidth='4xl'>
                    <Tabs
                        isFitted
                        minHeight='max-content'
                        variant='line'
                        colorScheme='brand_secondary'>
                        <TabList flexWrap='wrap'>
                            {tabsItems.map( ( tabItem ) => (
                                <Tab
                                    key={tabItem._key}
                                    minWidth={['100%', 'auto']}
                                    _focus={{ boxShadow: 'none' }}>
                                    {tabItem.tabItemTitle}
                                </Tab>
                            ) )}
                        </TabList>
                        <TabPanels>
                            {tabsItems.map( ( tabItem ) => (
                                <TabPanel key={tabItem._key} p={10}>
                                    <Box maxWidth='2xl' mx='auto'>
                                        <PortableText
                                            value={tabItem.tabItemContent}
                                            components={contentBuilderBodySerializer} />
                                    </Box>
                                </TabPanel>
                            ) )}
                        </TabPanels>
                    </Tabs>
                </Container>
            )}
        </Flex>
    )
}

export default ContentTabs
