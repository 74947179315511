/**
 * Nosy Content Item Title
 *
 * @export ContentTitle
 */
import { Flex, Heading, useToken } from '@chakra-ui/react'
import Color from 'color'
import React from 'react'
 
const ContentTitle = ( {
    contentIndex = null,
    contentAnchor = {},
    contentTitleTitle = '',
    contentTitleBackgroundColor = {}
} ) => {

    // Get default fallback colors from theme
    const [ brandGrayscaleBlack ] = useToken( 
        'colors', [
            'brand_grayscale.black'
        ]
    )

    const { 
        hex: contentTitleBackgroundColorHex = brandGrayscaleBlack 
    } = contentTitleBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( contentTitleBackgroundColorHex ).isDark() 
        ? 'gray.200' 
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            height='full'
            bg={contentTitleBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={6}>
            <Heading
                as='h1'
                maxWidth='5xl'
                fontSize={[ '3xl', '4xl', '6xl' ]}
                size='3xl'
                color={textColor}
                fontWeight='extrabold'
                mx='auto'
                textAlign='center'
                lineHeight='1.2'
                letterSpacing='tight'
                mb={12}>
                {contentTitleTitle}
            </Heading>
        </Flex>
    )
}

export default ContentTitle