/**
 * Nosy Content Map
 * 
 * Here's the magic sauce 🧙 Dynamic Components
 *
 * Create map lookup of component option mame to a corresponding {imported} React Component.
 * ⚠️ Component option name MUST be specified in Sanity data schema ⚠️ i.e.
 * (<Sanity-Project>/schemas/objects/content-section.js)
 *
 * @export ContentMap
 */
import { Alert, AlertDescription, AlertTitle, Container } from '@chakra-ui/react'
import capitalize from 'capitalize'
import React from 'react'
import ContentAccordian from './content-accordian'
import ContentCallToAction from './content-call-to-action'
import ContentCallout from './content-callout'
import ContentGallery from './content-gallery'
import ContentHero from './content-hero'
import ContentIframe from './content-iframe'
import ContentImage from './content-image'
import ContentLeadForm from './content-lead-form'
import ContentLongForm from './content-long-form'
import ContentPostCategoryGrid from './content-post-category-grid'
import ContentPricingTable from './content-pricing-table'
import ContentQuote from './content-quote'
import ContentTabs from './content-tabs'
import ContentTitle from './content-title'
import ContentVideo from './content-video'
import ContentGrid from './content-grid'
 
// Create lookup map to match content type to a component 
const contentTypes = {
    contentHero: ContentHero,
    contentImage: ContentImage,
    contentGallery: ContentGallery,
    contentVideo: ContentVideo,
    contentCallout: ContentCallout,
    contentCallToAction: ContentCallToAction,
    contentIframe: ContentIframe,
    contentPricingTable: ContentPricingTable,
    contentLeadForm: ContentLeadForm,
    contentQuote: ContentQuote,
    contentLongForm: ContentLongForm,
    contentAccordian: ContentAccordian,
    contentTabs: ContentTabs,
    contentTitle: ContentTitle,
    contentGrid: ContentGrid,
    contentPostCategoryGrid: ContentPostCategoryGrid
}
 
const ContentMap = ( { contentType = '', ...rest } ) => {
    // Check Section Component exists, if not bail early & communicate error alert to user...
    if ( typeof contentTypes[contentType] === 'undefined' ) {
        return (
            <Container maxWidth='full' centerContent>
                <Alert status='error' variant='left-accent' borderRadius='sm' my={10} maxWidth='2xl'>
                    <AlertTitle mr={2}>Component not found</AlertTitle>
                    <AlertDescription>
                    Component <strong>"{capitalize( contentType.replace( 'content', '' ) )}"</strong> not found{' '}😕
                    </AlertDescription>
                </Alert>
            </Container>
        )
        // ... Else create Section Component and pass through props
    } else {
        const ContentItem = contentTypes[contentType]
        
        return <ContentItem {...rest} />
    }
}
 
export default ContentMap
 