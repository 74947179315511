/**
 * Nosy Content Item Content Post Category Grid
 *
 * @export ContentPostCategoryGrid
 */
import React from 'react'
import {
    Container,
    Divider,
    Flex,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    useBreakpointValue,
    useToken
} from '@chakra-ui/react'
import Image from '../image/image'
import Link from '../link/link'
import { PortableText } from '@portabletext/react'
import { excerptSerializer } from '../../serializers'
import SanityClientConstructor from '@sanity/client'
import { useEffect } from 'react'
import { useState } from 'react'

const ContentPostCategoryGrid = ( {
    contentIndex = null, 
    contentAnchor = {},
    postCategoryGridTitle = '',
    postCategoryGridCategory = {},
    postCategoryGridBackgroundColor = {}
} ) => {

    // Hold category posts in state
    const [posts, setPosts] = useState( null )

    // Get default fallback colors from theme
    const [
        brandColor, 
        brandGrayscaleBlack
    ] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )
    
    // Destructure color values and fallback to theme values
    const { 
        hex: postCategoryGridBackgroundColorHex = brandGrayscaleBlack 
    } = postCategoryGridBackgroundColor
    
    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Fetch posts from Sanity
    useEffect( () => {
        const { title = null } = postCategoryGridCategory

        // Bail early if no filter specified
        if ( !title ) { 
            return []
        }

        // Create Sanity Client
        const client = SanityClientConstructor( {
            projectId: process.env.GATSBY_SANITY_PROJECT_ID,
            dataset: process.env.GATSBY_SANITY_DATASET,
            apiVersion: '2021-03-25',
            useCdn: true
        } )
    
        const query = '*[_type == "post" && !(_id in path("drafts.**")) && $title in categories[]->title]|order(_createdAt desc)[0...6]{_id,title,excerpt,mainImage,slug}'
        const params = { title: title }
    
        const fetchData = async () => {
            const data = await client.fetch( query, params )
            setPosts( data )
        }

        fetchData()
    }, [postCategoryGridCategory] )

    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={postCategoryGridBackgroundColorHex}
            justifyContent='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Container maxWidth='6xl'>
                {postCategoryGridTitle && (
                    <Flex
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        pb={6}>
                        <Heading
                            color='gray.100'>
                            {postCategoryGridTitle}
                        </Heading>
                        <Flex flex='1' mx={10}>
                            <Divider borderColor='whiteAlpha.300' />
                        </Flex>
                    </Flex>
                )}
                {posts && (
                    <SimpleGrid 
                        columns={[1, 2, 3]} 
                        spacing={[4, 6, 8, 10]}>
                        {posts.map( ( content ) => (
                            <LinkBox 
                                key={content._id}
                                role='group'>
                                <Stack
                                    direction='column'
                                    height='full'
                                    spacing={8}
                                    mt={10}
                                    pb={10}>
                                    {content.mainImage && (
                                        <Image
                                            imageData={content.mainImage}
                                            height={280}
                                            width={imageWidth}
                                            sx={{
                                                width: 'full'
                                            }} />
                                    )}
                                    <LinkOverlay
                                        as={Link}
                                        to={content.slug ? `/blog/${content.slug.current}/` : '#'}>
                                        <Heading
                                            as='h3'
                                            color='gray.100'
                                            fontSize={['lg', 'xl', '2xl', '3xl']}
                                            transition='all 0.343s'
                                            _groupHover={{
                                                color: 'brand_secondary.500'
                                            }}>
                                            {content.title}
                                        </Heading>
                                    </LinkOverlay>
                                    <PortableText
                                        value={content.excerpt}
                                        components={excerptSerializer} />
                                </Stack>
                            </LinkBox>
                        ) )}
                    </SimpleGrid>
                )}
            </Container>
        </Flex>
    )
}

export default ContentPostCategoryGrid
