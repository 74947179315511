/**
 * Nosy Content Item Hero
 *
 * @export ContentPricingTable
 */
import { CheckCircleIcon } from '@chakra-ui/icons'
import {
    Box,
    Container,
    Flex,
    Heading,
    List,
    ListIcon,
    ListItem,
    SimpleGrid,
    Stack,
    Text,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import Color from 'color'
import React from 'react'
import { contentBuilderBodySerializer } from '../../serializers'

const ContentPricingTable = ( {
    contentIndex = null,
    contentAnchor = {},
    pricingTableTitle = null,
    pricingTableText = [],
    pricingTableFootnote = [],
    pricingTableItems = [],
    pricingTableBackgroundColor = {},
} ) => {
    // Get default fallback colors from theme
    const [ brandGrayscaleBlack ] = useToken( 
        'colors', [
            'brand_grayscale.black'
        ]
    )

    const { 
        hex: pricingTableBackgroundColorHex = brandGrayscaleBlack 
    } = pricingTableBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( pricingTableBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'
    
    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor} 
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={pricingTableBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {pricingTableTitle && (
                    <Heading
                        as='h1'
                        size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'
                        mb={12}>
                        {pricingTableTitle}
                    </Heading>
                )}
            </Stack>
            
            <Container maxWidth='4xl'>
                <Box 
                    fontSize='lg' 
                    textAlign='center' 
                    color={textColor}>
                    <PortableText
                        value={pricingTableText}
                        components={contentBuilderBodySerializer} />
                </Box>
            </Container>
            
            <Container maxWidth='6xl'>
                <SimpleGrid
                    my={10}
                    mx='auto'
                    minChildWidth='300px'
                    spacing={8}
                    width='min'
                    maxWidth='full'>
                    {pricingTableItems &&
                        pricingTableItems.map( ( pricingTableItem ) => (
                            <Flex
                                key={pricingTableItem._key}
                                direction='column'
                                minWidth='xs'
                                mx='auto'>
                                <Stack
                                    direction='column'
                                    spacing={[5, null, 8]}
                                    alignItems='center'
                                    pb={10}
                                    mb={8}
                                    border='solid'
                                    height='full'
                                    borderColor='brand_secondary.500'>
                                    <Flex
                                        direction='column'
                                        bg='brand_secondary.500'
                                        width='full'
                                        justifyContent='center'
                                        px={6}
                                        py={8}>
                                        <Heading 
                                            as='h3' 
                                            textAlign='center' 
                                            fontSize='3xl'
                                            color='gray.100'>
                                            {pricingTableItem.title}
                                        </Heading>
                                        {pricingTableItem.subtitle &&
                                                <Heading 
                                                    as='h4' 
                                                    py={4} 
                                                    textAlign='center' 
                                                    fontSize='xl' 
                                                    color='gray.200'>
                                                    {pricingTableItem.subtitle}
                                                </Heading>
                                        }
                                    </Flex>
                                    <Box>
                                        <Heading 
                                            as='h4' 
                                            color='gray.100'
                                            mb={( pricingTableItem.priceSuffix ? 0 : 4 )}>
                                            £{pricingTableItem.price}{' '}
                                            <Text
                                                as='sup'
                                                top='-1rem'
                                                fontSize='sm'>
                                                + VAT
                                            </Text>
                                        </Heading>
                                        {pricingTableItem.priceSuffix && (
                                            <Text
                                                as='span'
                                                fontSize='sm'
                                                color='gray.100'>
                                                {pricingTableItem.priceSuffix}
                                            </Text>
                                        ) }
                                    </Box>
                                    {pricingTableItem.inclusionPrefix && (
                                        <Box>
                                            <Text color='gray.100'>{pricingTableItem.inclusionPrefix}</Text>
                                        </Box>
                                    )}
                                    {pricingTableItem.inclusions && (
                                        <List
                                            px={10}
                                            stylePosition='outside'
                                            spacing={3}>
                                            {pricingTableItem.inclusions.map(
                                                ( inclusion ) => (
                                                    <ListItem
                                                        key={inclusion}
                                                        display='flex'
                                                        alignItems='center'
                                                        color='gray.100'>
                                                        <ListIcon
                                                            alignSelf='flex-start'
                                                            mt={1}
                                                            as={CheckCircleIcon}
                                                            color='brand_secondary.500' />
                                                        {inclusion}
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    )}
                                </Stack>
                            </Flex>
                        ) )
                    }
                </SimpleGrid>
            </Container>

            <Container maxWidth='4xl'>
                <Box color={textColor} textAlign='center'>
                    <PortableText
                        value={pricingTableFootnote}
                        components={contentBuilderBodySerializer} />
                </Box>
            </Container>
        </Flex>
    )
}

export default ContentPricingTable
