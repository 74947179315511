/**
 * Nosy Content Item Video
 *
 * @export ContentVideo
 */

import {
    Alert,
    AlertDescription,
    AlertTitle,
    AspectRatio,
    Box,
    Button,
    Center,
    chakra,
    Container,
    Flex,
    Heading,
    Skeleton,
    Stack,
    useBreakpointValue,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import capitalize from 'capitalize'
import Color from 'color'
import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import {
    contentBuilderBodySerializer,
    contentBuilderExcerptSerializer
} from '../../serializers'
import { createAspectRatio } from '../../utils/helpers'
import Link from '../link/link'

//#region OneColumnFeature
const OneColumnFeature = ( {
    contentIndex = null,
    contentAnchor = {},
    videoTitle = null,
    videoText = [],
    videoUrl = '',
    videoAspectRatio = '',
    videoAutoplay = false,
    videoControls = false,
    videoLoop = false,
    videoBackgroundColor = {},
    ctaButton: {
        buttonLabel = '',
        buttonColor = {},
        customLink = false,
        destinationUrl = '',
        destinationRef = {},
        buttonNewTab = false
    }
} ) => {
    // Setup loading state
    const [loading, setLoading] = useState( true )

    // Modufy ReactPlayer to use Chakra UI theme values
    const ChakraReactPlayer = chakra( ReactPlayer, {
        shouldForwardProp: ( prop ) => prop
    } )

    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values, and assign fallback
    const { hex: videoBackgroundColorHex = brandGrayscaleBlack } =
        videoBackgroundColor

    const { hex: buttonColorHex = brandColor } = buttonColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( videoBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    let destination
    if ( customLink ) {
        destination = destinationUrl
    } else {
        destination = destinationRef.slug
            ? `${siteUrl}/${destinationRef.slug.current}/`
            : '#'
    }

    const videoQuality = useBreakpointValue( {
        base: 'auto', 
        sm: '360p',
        md: '720p',
        lg: '1080p'
    } )

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Bail early if no video/file asset supplied
    if ( !videoUrl ) {
        return null
    }

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={videoBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                px={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {videoTitle && (
                    <Heading
                        as='h1'
                        maxWidth='5xl'
                        fontSize={['3xl', '4xl', '6xl', '7xl']}
                        // size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'
                        mb={12}>
                        {videoTitle}
                    </Heading>
                )}
                {videoUrl && (
                    <AspectRatio ratio={createAspectRatio( videoAspectRatio )}>
                        <Skeleton
                            isLoaded={!loading}
                            startColor={
                                videoBackgroundColorHex === brandGrayscaleBlack
                                    ? 'brand_primary.700'
                                    : videoBackgroundColorHex
                            }
                            endColor='brand_grayscale.darker'
                            width='full'
                            speed={1}>
                            <ChakraReactPlayer
                                onReady={() => setLoading( false )}
                                url={videoUrl}
                                width='100%'
                                height='100%'
                                playing={videoAutoplay}
                                muted={videoAutoplay}
                                controls={videoControls}
                                config={{
                                    file: {
                                        attributes: {
                                            autoPlay: videoAutoplay,
                                            loop: videoLoop,
                                            muted: true,
                                            playsInline: true
                                        }
                                    },
                                    youtube: {
                                        playerVars: {
                                            autoplay: videoAutoplay ? 1 : 0,
                                            modestbranding: 1,
                                            playsinline: 1,
                                            loop: videoLoop ? 1 : 0
                                        }
                                    },
                                    vimeo: {
                                        playerOptions: {
                                            autoplay: videoAutoplay ? 1 : 0,
                                            byline: false,
                                            playsinline: true,
                                            loop: videoLoop,
                                            quality: videoQuality
                                        }
                                    }
                                }} />
                        </Skeleton>
                    </AspectRatio>
                )}
                {videoText && (
                    <Flex>
                        <Box
                            mt={10}
                            maxWidth='4xl'
                            mx='auto'
                            fontFamily='Modelica-Medium'
                            color={textColor}>
                            <PortableText
                                value={videoText}
                                components={contentBuilderExcerptSerializer} />
                        </Box>
                    </Flex>
                )}
            </Stack>
            {buttonLabel && (
                <Center>
                    <Button
                        as={Link}
                        to={destination}
                        variant='brand-outline'
                        target={buttonNewTab ? '_blank' : null}
                        mx='auto'
                        size='lg'
                        px={8}
                        fontWeight='bold'
                        fontSize='md'
                        borderColor={buttonColorHex}
                        _hover={{
                            borderColor: Color( buttonColorHex )
                                .darken( 0.125 )
                                .hex(),
                            backgroundColor: Color( buttonColorHex )
                                .darken( 0.125 )
                                .hex()
                        }}>
                        {buttonLabel}
                    </Button>
                </Center>
            )}
        </Flex>
    )
}
//#endregion

//#region TwoColumnText
const TwoColumnText = ( {
    contentIndex = null,
    contentAnchor = {},
    contentReverseLayout = false,
    videoTitle = null,
    videoText = [],
    videoUrl = '',
    videoAspectRatio = '',
    videoAutoplay = false,
    videoControls = false,
    videoLoop = false,
    videoBackgroundColor = {},
    ctaButton: {
        buttonLabel = '',
        buttonColor = {},
        customLink = false,
        destinationUrl = '',
        destinationRef = {},
        buttonNewTab = false
    }
} ) => {
    // Setup loading state
    const [loading, setLoading] = useState( true )

    // Modufy ReactPlayer to use Chakra UI theme values
    const ChakraReactPlayer = chakra( ReactPlayer, {
        shouldForwardProp: ( prop ) => prop
    } )

    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values, and assign fallback
    const { hex: videoBackgroundColorHex = brandGrayscaleBlack } =
        videoBackgroundColor

    const { hex: buttonColorHex = brandColor } = buttonColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( videoBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    let destination
    if ( customLink ) {
        destination = destinationUrl
    } else {
        destination = destinationRef.slug
            ? `${siteUrl}/${destinationRef.slug.current}/`
            : '#'
    }

    const videoQuality = useBreakpointValue( {
        base: 'auto', 
        sm: '360p',
        md: '720p',
        lg: '1080p'
    } )

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Bail early if no video/file asset supplied
    if ( !videoUrl ) {
        return null
    }

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}
            bg={videoBackgroundColorHex}
            justifyContent='center'
            alignItems='center'>
            <Flex
                px={[6, null, 8]}
                // direction={['column', null, 'row']}
                direction={[
                    contentReverseLayout ? 'column-reverse' : 'column',
                    null,
                    contentReverseLayout ? 'row-reverse' : 'row'
                ]}
                width='full'
                maxWidth={['xl', null, '3xl', '5xl', '6xl']}
                justifyContent='space-between'
                mx='auto'>
                <Stack width={['full', null, '30%']}>
                    {videoTitle && (
                        <Heading
                            as='h2'
                            color={textColor}
                            fontSize={['3xl', '4xl']}
                            fontWeight='extrabold'
                            lineHeight='1'
                            letterSpacing='tight'
                            mb={6}>
                            {videoTitle}
                        </Heading>
                    )}
                    <Box mt={4} fontSize='lg' color={textColor}>
                        <PortableText
                            value={videoText}
                            components={contentBuilderBodySerializer} />
                    </Box>
                    {buttonLabel && (
                        <Button
                            as={Link}
                            to={destination}
                            variant='brand-outline'
                            target={buttonNewTab ? '_blank' : null}
                            size='lg'
                            width='min'
                            fontWeight='bold'
                            fontSize='md'
                            borderColor={buttonColorHex}
                            _hover={{
                                borderColor: Color( buttonColorHex )
                                    .darken( 0.125 )
                                    .hex(),
                                backgroundColor: Color( buttonColorHex )
                                    .darken( 0.125 )
                                    .hex()
                            }}>
                            {buttonLabel}
                        </Button>
                    )}
                </Stack>
                <Stack width={['full', null, '60%']} py={[12, 6, 3, 0]}>
                    {videoUrl && (
                        <AspectRatio
                            ratio={createAspectRatio( videoAspectRatio )}>
                            <Skeleton
                                isLoaded={!loading}
                                startColor={
                                    videoBackgroundColorHex === brandGrayscaleBlack
                                        ? 'brand_primary.700'
                                        : videoBackgroundColorHex
                                }
                                endColor='brand_grayscale.darker'
                                width='full'
                                speed={1}>
                                <ChakraReactPlayer
                                    onReady={() => setLoading( false )}
                                    url={videoUrl}
                                    width='100%'
                                    height='100%'
                                    playing={videoAutoplay}
                                    muted={videoAutoplay}
                                    controls={videoControls}
                                    config={{
                                        file: {
                                            attributes: {
                                                autoPlay: videoAutoplay,
                                                loop: videoLoop,
                                                muted: true,
                                                playsInline: true
                                            }
                                        },
                                        youtube: {
                                            playerVars: {
                                                autoplay: videoAutoplay ? 1 : 0,
                                                modestbranding: 1,
                                                playsinline: 1,
                                                loop: videoLoop ? 1 : 0
                                            }
                                        },
                                        vimeo: {
                                            playerOptions: {
                                                autoplay: videoAutoplay ? 1 : 0,
                                                byline: false,
                                                playsinline: true,
                                                loop: videoLoop,
                                                quality: videoQuality
                                            }
                                        }
                                    }} />
                            </Skeleton>
                        </AspectRatio>
                    )}
                </Stack>
            </Flex>
        </Flex>
    )
}
//#endregion

//#region OneColumnBackgroundVideo
const OneColumnBackgroundVideo = ( {
    contentIndex = null,
    contentAnchor = {},
    videoTitle = null,
    videoText = [],
    videoAsset = {},
    videoAspectRatio = '',
    videoBackgroundColor = {},
    ctaButton: {
        buttonLabel = '',
        buttonColor = {},
        customLink = false,
        destinationUrl = '',
        destinationRef = {},
        buttonNewTab = false
    }
} ) => {
    // console.log( '🦄 ~ file: content-video.js ~ line 419 ~ videoAsset', videoAsset )
    // Setup loading state
    const [loading, setLoading] = useState( true )

    // Modufy ReactPlayer to use Chakra UI theme values
    const ChakraReactPlayer = chakra( ReactPlayer, {
        shouldForwardProp: ( prop ) => prop
    } )

    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values and fallback to theme values
    const { hex: videoBackgroundColorHex = brandGrayscaleBlack } =
        videoBackgroundColor

    // Destructure color values
    const { hex: buttonColorHex = brandColor } = buttonColor

    // Specify text color
    const textColor = 'gray.200'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    // Resolve link destination urls
    let destination
    if ( customLink ) {
        destination = destinationUrl
    } else {
        destination = destinationRef.slug
            ? `${siteUrl}/${destinationRef.slug.current}/`
            : '#'
    }

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    if ( !videoAsset ) {
        return null
    }

    return (
        <Flex
            id={anchor}
            as='section'
            justifyContent='center'
            alignItems='center'
            bg={videoBackgroundColorHex}
            position='relative'
            mt={contentIndex === 0 ? -40 : 12}
            mb={12}>
            {videoAsset.asset && (
                <Flex 
                    direction='column' 
                    width='full' 
                    height='full'>
                    <AspectRatio 
                        minHeight={[ '70vh', '60vh', '55vh', '50vh', '45vh' ]} 
                        ratio={ createAspectRatio( videoAspectRatio ) }
                        position='relative'>
                        <Skeleton
                            flexDirection='column'
                            isLoaded={!loading}
                            startColor={
                                videoBackgroundColorHex === brandGrayscaleBlack
                                    ? 'brand_primary.700'
                                    : videoBackgroundColorHex
                            }
                            endColor='brand_grayscale.darker'
                            width='full'
                            height='full'
                            speed={1}>
                            <ChakraReactPlayer
                                onReady={() => setLoading( false )}
                                url={videoAsset.asset.url}
                                width='100%'
                                height='100%'
                                playing={( loading === false )}
                                muted={true}
                                controls={false}
                                sx={{
                                    '& video': {
                                        objectFit: 'cover'
                                    }
                                }}
                                config={{
                                    file: {
                                        attributes: {
                                            autoplay: true,
                                            loop: true,
                                            muted: true,
                                            playsinline: true
                                        }
                                    }
                                }}
                                _after={{
                                    content: '""',
                                    display: 'block',
                                    width: 'full',
                                    height: 'full',
                                    bg: 'blackAlpha.700',
                                    position: 'absolute',
                                    inset: 0,
                                    zIndex: 1
                                }} />
                            <Flex
                                position='absolute'
                                width='full'
                                height='full'
                                justifyContent='center'
                                alignItems='center'
                                mx='auto'
                                px={{
                                    base: '6',
                                    md: '8'
                                }}
                                zIndex={2}>
                                <Center
                                    flexDirection='column'
                                    textAlign='center'
                                    color='white'
                                    height='full'>
                                    <Heading
                                        as='h1'
                                        maxWidth={[
                                            'full',
                                            '2xl',
                                            '3xl',
                                            null,
                                            '5xl'
                                        ]}
                                        fontSize={[
                                            '2xl',
                                            '3xl',
                                            '4xl',
                                            '5xl',
                                            '7xl'
                                        ]}
                                        mt={[ 0, 0, 16, 0 ]}
                                        mx='auto'
                                        fontWeight='extrabold'
                                        color={textColor}
                                        // lineHeight='1'
                                        letterSpacing='tight'>
                                        {videoTitle}
                                    </Heading>
                                    <Box
                                        mt={[ 6, 8, 10]}
                                        fontSize='lg'
                                        fontWeight='medium'
                                        color={textColor}
                                        maxWidth='4xl'>
                                        <PortableText
                                            value={videoText}
                                            components={contentBuilderExcerptSerializer} />
                                    </Box>
                                    {buttonLabel && (
                                        <Button
                                            as={Link}
                                            to={destination}
                                            variant='brand-outline'
                                            target={
                                                buttonNewTab
                                                    ? '_blank'
                                                    : null
                                            }
                                            size='lg'
                                            px={8}
                                            fontWeight='bold'
                                            fontSize='md'
                                            borderColor={buttonColorHex}
                                            _hover={{
                                                borderColor: Color(
                                                    buttonColorHex
                                                )
                                                    .darken( 0.125 )
                                                    .hex(),
                                                backgroundColor: Color(
                                                    buttonColorHex
                                                )
                                                    .darken( 0.125 )
                                                    .hex()
                                            }}>
                                            {buttonLabel}
                                        </Button>
                                    )}
                                </Center>
                            </Flex>
                        </Skeleton>
                    </AspectRatio>
                </Flex>
            )}
        </Flex>
    )
}
//#endregion

const ContentVideo = ( { videoType = '', ...rest } ) => {
    // Create lookup map to match video type to a component
    const videoTypes = {
        'one-column-feature': OneColumnFeature,
        'two-column-text': TwoColumnText,
        'one-column-background-video': OneColumnBackgroundVideo
    }

    if ( typeof videoTypes[videoType] === 'undefined' ) {
        return (
            <Container maxWidth='full' centerContent>
                <Alert
                    status='error'
                    variant='left-accent'
                    borderRadius='sm'
                    my={10}
                    maxWidth='2xl'>
                    <AlertTitle mr={2}>Video Component not found</AlertTitle>
                    <AlertDescription>
                        Component <strong>"{capitalize( videoType )}"</strong> not
                        found 😕
                    </AlertDescription>
                </Alert>
            </Container>
        )
        // ... Else create Hero Component and pass through props
    } else {
        const VideoComponent = videoTypes[videoType]
        return <VideoComponent {...rest} />
    }
}

export default ContentVideo
