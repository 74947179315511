/**
 * Nosy Content Item Lead Form
 *
 * @export ContentLeadForm
 */
import {
    Alert,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertTitle,
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Input,
    Stack,
    Text,
    Textarea,
    useDisclosure,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import capitalize from 'capitalize'
import Color from 'color'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { contentBuilderExcerptSerializer } from '../../serializers'

const ContentLeadForm = ( {
    contentIndex = null,
    contentAnchor = {},
    leadFormSource = '',
    leadFormTitle = null,
    leadFormText = [],
    leadFormFields = [],
    leadFormFootnote = [],
    leadFormEmailDestination = '',
    leadFormBackgroundColor = {}
} ) => {
    // Get default fallback colors from theme
    const [ brandGrayscaleBlack ] = useToken( 
        'colors', [ 'brand_grayscale.black' ] 
    )

    const { 
        hex: leadFormBackgroundColorHex = brandGrayscaleBlack 
    } = leadFormBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( leadFormBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Initiate react-hook-form
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty, isValid }
    } = useForm( { mode: 'onBlur' } )

    // Form field type Map
    const formFieldTypes = {
        text: Input,
        email: Input,
        // 'number': Input,
        textarea: Textarea
    }

    // Use local state to track submissions/modal states
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ serverResponse, setServerResponse ] = useState( '' )
    const cancelRef = useRef()


    // Handle form submissions
    const onSubmit = async ( data ) => {
        // Debug submission values
        // console.log( { data } )

        // Send data to Gatsby Serverless Function, pass through destination email address & Lead Form Source to data        
        try {
            const response = await fetch( '/api/sendgrid', {
                method: 'POST',
                body: JSON.stringify( { leadFormEmailDestination, leadFormSource, ...data } ),
                headers: {
                    'content-type': 'application/json',
                }
            } )
            const responseData = await response.json()
            // console.log('🦄 ~ file: content-lead-form.js ~ line 132 ~ onSubmit ~ responseData', { responseData })

            // Save server response
            setServerResponse( responseData.status )

            // Show modal
            onOpen()

            // Conditionally reset form
            if ( responseData.status === 'success' ) {
                reset()
            }
            
        } catch ( error ) {
            console.error( error )
        }
    }

    return (
        <Flex
            id={anchor} 
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={leadFormBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                px={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {leadFormTitle && (
                    <Heading
                        as='h1'
                        size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'>
                        {leadFormTitle}
                    </Heading>
                )}
                <Box fontSize='lg' textAlign='center' color={textColor}>
                    <PortableText
                        value={leadFormText}
                        components={contentBuilderExcerptSerializer} />
                </Box>
            </Stack>
            <Container maxWidth='6xl'>
                <Box 
                    as='form' 
                    onSubmit={handleSubmit( onSubmit )} 
                    maxWidth='2xl'
                    mx='auto'>
                    <Grid
                        my={10}
                        templateColumns='repeat(2, 1fr)' 
                        gap={10}
                        maxWidth='4xl'
                        mx='auto'
                        width='full'>
                        {leadFormFields &&
                            leadFormFields.map( ( formField ) => {
                                // Check Section Component exists, if not bail early & communicate error alert to user
                                if (
                                    typeof formFieldTypes[
                                        formField.leadFormFieldType
                                    ] === 'undefined'
                                ) {
                                    return (
                                        <Alert
                                            status='error'
                                            variant='left-accent'
                                            borderRadius='sm'
                                            my={10}>
                                            <AlertTitle mr={2}>
                                                Form Field "
                                                {capitalize(
                                                    formField.leadFormFieldType
                                                )}
                                                "not found
                                            </AlertTitle>
                                            <AlertDescription></AlertDescription>
                                        </Alert>
                                    )
                                    // ... Else create Section Component and pass through props
                                } else {
                                    const FormField =
                                        formFieldTypes[
                                            formField.leadFormFieldType
                                        ]

                                    return (
                                        <GridItem 
                                            key={formField._key} 
                                            colSpan={formField.leadFormFieldType !== 'textarea' ? [2, 1] : 2}>
                                            <FormControl
                                                isRequired={formField.leadFormFieldRequired}
                                                isInvalid={
                                                    errors[
                                                        formField.leadFormFieldTitle
                                                    ]
                                                }>
                                                <FormLabel
                                                    htmlFor={formField.leadFormFieldTitle}>
                                                    {formField.leadFormFieldLabel}
                                                </FormLabel>
                                                <FormField
                                                    id={formField.leadFormFieldTitle}
                                                    placeholder={formField.leadFormFieldPlaceholder}
                                                    type={formField.leadFormFieldType}
                                                    borderRadius='none'
                                                    size='lg'
                                                    focusBorderColor='brand_primary.700'
                                                    {...register(
                                                        formField.leadFormFieldTitle,
                                                        {
                                                            required:
                                                            formField.leadFormFieldRequired
                                                                ? formField.leadFormFieldErrorMessage
                                                                : null,
                                                            pattern:
                                                            formField.leadFormFieldType ===
                                                            'email'
                                                                ? {
                                                                    value: /\S+@\S+\.\S+/,
                                                                    message:
                                                                          'Entered value does not match email format'
                                                                }
                                                                : null
                                                        }
                                                    )} />
                                                <FormErrorMessage>
                                                    {errors[formField.leadFormFieldTitle] &&
                                                    errors[formField.leadFormFieldTitle].message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </GridItem>
                                    )
                                }
                            } )}
                    </Grid>

                    <Flex
                        width='full'
                        my={10}
                        justifyContent='center'
                        alignItems='center'>
                        <Button
                            variant='brand-outline'
                            isLoading={isSubmitting}
                            disabled={!isDirty || !isValid}
                            type='submit'>
                            Send enquiry
                        </Button>
                    </Flex>
                </Box>
            </Container>
            <Container maxWidth='8xl'>
                <Box color={textColor} textAlign='center' mt={10}>
                    <PortableText
                        value={leadFormFootnote}
                        components={contentBuilderExcerptSerializer} />
                </Box>
            </Container>
            
            <Container maxWidth='6xl'>
                <AlertDialog
                    motionPreset='slideInBottom'
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                    isOpen={isOpen}
                    isCentered>
                    <AlertDialogOverlay />
                    <AlertDialogContent 
                        borderRadius='none' 
                        backgroundColor='brand_grayscale.dark'>
                        <AlertDialogHeader color='brand_primary.700'>
                            {serverResponse === 'success' 
                                ? 'Message sent'
                                : 'Oh no!'
                            }
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            {serverResponse === 'success'
                                ? ( <Text>Thank you, we'll be in touch shortly.</Text> )
                                : ( <Text>Sorry, we're experiencing a technical issue,
                                please try again later.</Text> )
                            }
                        </AlertDialogBody>
                        <AlertDialogFooter />
                    </AlertDialogContent>
                </AlertDialog>
            </Container>
        </Flex>
    )
}

export default ContentLeadForm
