/**
 * Nosy Content Item Content Grid
 *
 * @export ContentGrid
 */
import React from 'react'
import {
    Container,
    Divider,
    Flex,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useToken
} from '@chakra-ui/react'
import Image from '../image/image'
import Link from '../link/link'
import { PortableText } from '@portabletext/react'
import { excerptSerializer } from '../../serializers'

// #region PostCard
const PostCard = ( content ) => {
    
    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    return (
        <LinkBox 
            key={content.id}
            role='group'>
            <Stack
                direction='column'
                height='full'
                spacing={8}
                mt={10}
                pb={10}>
                {content.mainImage && (
                    <Image
                        imageData={content.mainImage}
                        height={280}
                        width={imageWidth}
                        sx={{
                            width: 'full'
                        }} />
                )}
                <LinkOverlay
                    as={Link}
                    to={content.slug ? `/blog/${content.slug.current}/` : '#'}>
                    <Heading
                        as='h3'
                        color='gray.100'
                        fontSize={['lg', 'xl', '2xl', '3xl']}
                        transition='all 0.343s'
                        _groupHover={{
                            color: 'brand_secondary.500'
                        }}>
                        {content.title}
                    </Heading>
                </LinkOverlay>
                <PortableText
                    value={content.excerpt}
                    components={excerptSerializer} />
            </Stack>
        </LinkBox>
    )
}
// #endregion

// #region TeamCard
const TeamCard = ( content ) => {
    
    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    return (
        <LinkBox 
            key={content.id}
            role='group'>
            <Stack
                direction='column'
                height='full'
                spacing={8}
                mt={10}
                pb={10}>
                {content.mainImage && (
                    <Image
                        imageData={content.mainImage}
                        height={280}
                        width={imageWidth}
                        sx={{
                            width: 'full'
                        }} />
                )}
                <LinkOverlay
                    as={Link}
                    to={content.slug ? `/team/${content.slug.current}/` : '#'}>
                    <Heading
                        as='h3'
                        color='gray.100'
                        fontSize={['lg', 'xl', '2xl', '3xl']}
                        transition='all 0.343s'
                        _groupHover={{
                            color: 'brand_secondary.500'
                        }}>
                        {content.name}
                    </Heading>
                </LinkOverlay>
                <Text 
                    mb={10} 
                    fontSize='md' 
                    maxWidth='2xl' 
                    mx='auto' 
                    color='gray.400'>
                    {content.role}
                </Text>
            </Stack>
        </LinkBox>
    )
}
// #endregion

// #region PageCard
const PageCard = ( content ) => {
    
    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    return (
        <LinkBox 
            key={content.id}
            role='group'>
            <Stack
                direction='column'
                height='full'
                spacing={8}
                mt={10}
                pb={10}>
                {content.mainImage && (
                    <Image
                        imageData={content.mainImage}
                        height={280}
                        width={imageWidth}
                        sx={{
                            width: 'full'
                        }} />
                )}
                <LinkOverlay
                    as={Link}
                    to={content.slug ? `/${content.slug.current}/` : '#'}>
                    <Heading
                        as='h3'
                        color='gray.100'
                        fontSize={['lg', 'xl', '2xl', '3xl']}
                        transition='all 0.343s'
                        _groupHover={{
                            color: 'brand_secondary.500'
                        }}>
                        {content.title}
                    </Heading>
                </LinkOverlay>
                <PortableText
                    value={content.excerpt}
                    components={excerptSerializer} />
            </Stack>
        </LinkBox>
    )
}
// #endregion

// #region WorkCard
const WorkCard = ( content ) => {
    
    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    return (
        <LinkBox 
            key={content.id}
            role='group'>
            <Stack
                direction='column'
                height='full'
                spacing={8}
                mt={10}
                pb={10}>
                {content.featureImage && (
                    <Image
                        imageData={content.featureImage}
                        height={280}
                        width={imageWidth}
                        sx={{
                            width: 'full'
                        }} />
                )}
                <LinkOverlay
                    as={Link}
                    to={content.slug ? `/work/${content.slug.current}/` : '#'}>
                    <Heading
                        as='h3'
                        color='gray.100'
                        fontSize={['lg', 'xl', '2xl', '3xl']}
                        transition='all 0.343s'
                        _groupHover={{
                            color: 'brand_secondary.500'
                        }}>
                        {content.title}
                    </Heading>
                </LinkOverlay>
                <PortableText
                    value={content.excerpt}
                    components={excerptSerializer} />
            </Stack>
        </LinkBox>
    )
}
// #endregion

// #region LandingPageCard
const LandingPageCard = ( content ) => {

    const imageWidth = useBreakpointValue( { 
        base: 447, 
        sm: 356,
        md: 356,
        lg: 300,
        xl: 347
    } )

    const { seo: { seoDescription = '' } } = content

    return (
        <LinkBox 
            key={content.id}
            role='group'>
            <Stack
                direction='column'
                height='full'
                spacing={8}
                mt={10}
                pb={10}>
                {content.mainImage && (
                    <Image
                        imageData={content.mainImage}
                        height={280}
                        width={imageWidth}
                        sx={{
                            width: 'full'
                        }} />
                )}
                <LinkOverlay
                    as={Link}
                    to={content.slug ? `/${content.slug.current}/` : '#'}>
                    <Heading
                        as='h3'
                        color='gray.100'
                        fontSize={['lg', 'xl', '2xl', '3xl']}
                        transition='all 0.343s'
                        _groupHover={{
                            color: 'brand_secondary.500'
                        }}>
                        {content.title}
                    </Heading>
                </LinkOverlay>
                {seoDescription && (
                    <Text 
                        mb={10} 
                        fontSize='md' 
                        maxWidth='2xl' 
                        mx='auto' 
                        color='gray.400'>
                        {seoDescription}
                    </Text>
                )}
            </Stack>
        </LinkBox>
    )
}
// #endregion

const ContentGrid = ( {
    contentIndex = null, 
    contentAnchor = {},
    contentGridTitle = '',
    contentGridContent = [],
    contentGridBackgroundColor = {},
} ) => {

    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values and fallback to theme values
    const { 
        hex: contentGridBackgroundColorHex = brandGrayscaleBlack 
    } = contentGridBackgroundColor

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={contentGridBackgroundColorHex}
            justifyContent='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Container maxWidth='6xl'>
                {contentGridTitle && (
                    <Flex
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        pb={6}>
                        <Heading
                            color='gray.100'>
                            {contentGridTitle}
                        </Heading>
                        <Flex flex='1' mx={10}>
                            <Divider borderColor='whiteAlpha.300' />
                        </Flex>
                    </Flex>
                )}
                <SimpleGrid 
                    columns={[1, 2, 3]} 
                    spacing={[4, 6, 8, 10]}>
                    {contentGridContent.map( ( content ) => {

                        // Lookup match _type to component
                        const contentTypeMap = {
                            'post': PostCard,
                            'team': TeamCard,
                            'page' : PageCard,
                            'work' : WorkCard,
                            'landingPage' : LandingPageCard,
                        }

                        if ( typeof contentTypeMap[content._type] === 'undefined' ) {
                            return null
                        }

                        const CardComponent = contentTypeMap[content._type] 
                        return <CardComponent key={content._id} {...content} />
                    } )}
                </SimpleGrid>
            </Container>
        </Flex>
    )
}

export default ContentGrid
