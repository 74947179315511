/**
 * Nosy Content Item Call To Action
 *
 * @export ContentCallToAction
 */

import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box,
    Button, Container, Flex,
    Heading,
    Stack, useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import capitalize from 'capitalize'
import Color from 'color'
import React from 'react'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { contentBuilderBodySerializer } from '../../serializers'
import Link from '../link/link'

//#region SimpleCentred
const SimpleCentred = ( {
    contentIndex = null,
    contentAnchor = {},
    ctaHeading = '',
    ctaText = [],
    backgroundColor = {},
    ctaButton: {
        buttonLabel = '',
        buttonColor = {},
        customLink = false,
        destinationUrl = '',
        destinationRef = {},
        buttonNewTab = false
    }
} ) => {
    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values
    const { 
        hex: ctaBackgroundColorHex = brandGrayscaleBlack 
    } = backgroundColor
    const { hex: buttonColorHex = brandColor } = buttonColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( ctaBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    let destination
    if ( customLink ) {
        destination = destinationUrl
    } else {
        destination = ( destinationRef.slug ? `${siteUrl}/${destinationRef.slug.current}/` : '#' )
    }

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={ctaBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Box
                maxWidth='2xl'
                mx='auto'
                px={{
                    base: '6',
                    lg: '8'
                }}
                py={{
                    base: '16',
                    sm: '20'
                }}>
                <Heading
                    as='h2'
                    size='3xl'
                    color={textColor}
                    fontWeight='extrabold'
                    letterSpacing='tight'
                    mb={10}>
                    {ctaHeading}
                </Heading>
                <Box 
                    mt={4} 
                    fontSize='lg'
                    color={textColor}>
                    <PortableText
                        value={ctaText}
                        components={contentBuilderBodySerializer} />
                </Box>
                <Button
                    as={Link}
                    to={destination}
                    variant='brand-outline'
                    target={buttonNewTab ? '_blank' : null}
                    size='lg'
                    px={8}
                    fontWeight='bold'
                    fontSize='md'
                    borderColor={buttonColorHex}
                    _hover={{
                        borderColor: Color( buttonColorHex )
                            .darken( 0.125 )
                            .hex(),
                        backgroundColor: Color( buttonColorHex )
                            .darken( 0.125 )
                            .hex()
                    }}>
                    {buttonLabel}
                </Button>
            </Box>
        </Flex>
    )
}
//#endregion

//#region TwoButtonCentered
const TwoButtonCentered = ( {
    contentIndex = null,
    contentAnchor = {},
    ctaHeading = '',
    ctaText = [],
    backgroundColor = {},
    ctaButtons = []
} ) => {
    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values
    const { 
        hex: ctaBackgroundColorHex = brandGrayscaleBlack 
    } = backgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( ctaBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Get siteURL to resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor} 
            as='section'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={ctaBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Box
                maxWidth='2xl'
                mx='auto'
                px={{
                    base: '6',
                    lg: '8'
                }}
                py={{
                    base: '16',
                    sm: '20'
                }}>
                <Heading
                    my='4'
                    as='h2'
                    color={textColor}
                    fontSize={{
                        base: '4xl',
                        md: '6xl'
                    }}
                    fontWeight='extrabold'
                    letterSpacing='tight'
                    lineHeight='1.2'>
                    {ctaHeading}
                </Heading>
                <Box 
                    mt={4} 
                    fontSize='lg'
                    color={textColor}>
                    <PortableText
                        value={ctaText}
                        components={contentBuilderBodySerializer} />
                </Box>
                {ctaButtons && (
                    <Stack
                        direction={{
                            base: 'column',
                            sm: 'row'
                        }}
                        mt='10'
                        // justifyContent='center'
                        justifyContent='flex-start'
                        mx='auto'
                        // maxWidth='md'
                        spacing={{
                            base: '3',
                            md: '5'
                        }}>
                        {ctaButtons.map( ( node ) => {
                            const {
                                _key: key = '',
                                buttonLabel = '',
                                buttonColor = {},
                                customLink = false,
                                destinationUrl = '',
                                destinationRef = {},
                                buttonNewTab = false
                            } = node

                            // Destructure color values
                            const { hex: buttonColorHex = brandColor } = buttonColor

                            // Resolve link destination urls
                            let destination
                            if ( customLink ) {
                                destination = destinationUrl
                            } else {
                                destination = ( destinationRef.slug ? `${siteUrl}/${destinationRef.slug.current}/` : '#' )
                            }

                            return (
                                <Button
                                    key={key}
                                    as={Link}
                                    to={destination}
                                    variant='brand-outline'
                                    target={buttonNewTab ? '_blank' : null}
                                    size='lg'
                                    px={8}
                                    fontWeight='bold'
                                    fontSize='md'
                                    borderColor={buttonColorHex}
                                    _hover={{
                                        borderColor: Color( buttonColorHex )
                                            .darken( 0.125 )
                                            .hex(),
                                        backgroundColor: Color( buttonColorHex )
                                            .darken( 0.125 )
                                            .hex()
                                    }}>
                                    {buttonLabel}
                                </Button>
                            )
                        } )}
                    </Stack>
                )}
            </Box>
        </Flex>
    )
}
//#endregion

const ContentCallToAction = ( { ctaType = '', ...rest } ) => {
    // Create lookup map to match cta type to a component
    const ctaTypes = {
        'simple-centred': SimpleCentred,
        'two-button-centred': TwoButtonCentered
    }

    if ( typeof ctaTypes[ctaType] === 'undefined' ) {
        return (
            <Container maxWidth='full' centerContent>
                <Alert status='error' variant='left-accent' borderRadius='sm' my={10} maxWidth='2xl'>
                    <AlertTitle mr={2}>CTA Component not found</AlertTitle>
                    <AlertDescription>
                    Component <strong>"{capitalize( ctaType )}"</strong> not found
                    😕
                    </AlertDescription>
                </Alert>
            </Container>
        )
        // ... Else create Hero Component and pass through props
    } else {
        const CtaComponent = ctaTypes[ctaType]
        return <CtaComponent {...rest} />
    }
}

export default ContentCallToAction
