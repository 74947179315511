/**
 * Nosy Content Item Quote
 *
 * @export ContentQuote
 */
import { Flex, Stack, Text, useToken } from '@chakra-ui/react'
import Color from 'color'
import React from 'react'
import Image from '../image/image'
import QuoteMark from '../quote-mark/quote-mark'

const ContentQuote = ({
    contentIndex = null,
    contentAnchor = {},
    quoteImage = {},
    quoteStrapline = '',
    quoteCitation = '',
    quoteBackgroundColor = {}
}) => {
    // Get default fallback colors from theme
    const [brandGrayscaleBlack] = useToken('colors', ['brand_grayscale.black'])

    const { hex: quoteBackgroundColorHex = brandGrayscaleBlack } =
        quoteBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color(quoteBackgroundColorHex).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={quoteBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                maxWidth='xl'
                mx='auto'
                spacing={10}
                pt={6}
                px={{
                    base: '6',
                    lg: '8'
                }}
                mt={[10, null, -20]}>
                <QuoteMark width={16} height={16} color='brand_secondary.500' />
                {quoteImage && (
                    <Image
                        imageData={quoteImage}
                        width={180}
                        objectFit='contain'
                    />
                )}
                <Text fontSize='2xl' lineHeight='base' color={textColor}>
                    {quoteStrapline}
                </Text>
                <Text
                    fontSize='md'
                    color='brand_grayscale.mid'
                    fontStyle='italic'>
                    {quoteCitation}
                </Text>
            </Stack>
        </Flex>
    )
}

export default ContentQuote
