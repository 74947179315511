/**
 * Nosy Content Item Callout
 *
 * @export ContentCallout
 */
import { Box, Flex, Heading, Text, useToken } from '@chakra-ui/react'
import Color from 'color'
import React from 'react'

const ContentCallout = ( { 
    contentIndex = null,
    contentAnchor = {},
    calloutTitle = '', 
    calloutStrapline = '', 
    calloutBackgroundColor = {} 
} ) => {

    // Get default fallback colors from theme
    const [ brandGrayscaleBlack ] = useToken( 
        'colors', [
            'brand_grayscale.black'
        ]
    )

    const { 
        hex: calloutBackgroundColorHex = brandGrayscaleBlack 
    } = calloutBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( calloutBackgroundColorHex ).isDark() 
        ? 'gray.200' 
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={calloutBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Box
                maxWidth='2xl'
                mx='auto'
                px={{
                    base: '6',
                    lg: '8'
                }}
                py={{
                    base: '16',
                    sm: '20'
                }}>
                <Heading
                    color={textColor}
                    as='h2'
                    size='3xl'
                    fontWeight='extrabold'
                    letterSpacing='tight'
                    mb={10}>
                    {calloutTitle}
                </Heading>
                <Text mt='4' fontSize='2xl' color={textColor} textAlign='start'>
                    {calloutStrapline}
                </Text>
            </Box>
        </Flex>
    )
}

export default ContentCallout