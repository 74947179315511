/**
 * Nosy Landing Page Template
 *
 * @export LandingPage
 */

import { Container, Stack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import CallToAction from '../../components/call-to-action/call-to-action'
import ContentMap from '../../components/content/content-map'
import Layout from '../../components/layout/layout'
import SEO from '../../components/seo/seo'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_LANDING_PAGE_QUERY($id: String!) {
        sanityLandingPage(id: { eq: $id }) {
            # title
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            defaultLayout
            includeSiteCta
            content: _rawContent(resolveReferences: { maxDepth: 10 })
            mainImage {
                ...ImageWithPreview
            }
            slug {
                current
            }
        }
    }
`

const LayoutWrapper = ( { defaultLayout = false, children = {} } ) => {
    // If useDefaultLayout is true, wrap content in default Layout
    if ( defaultLayout ) {
        return <Layout>{children}</Layout>
    }

    // Otherwise return content without default layout
    return children
}

const LandingPage = ( props ) => {
    // console.log( '🦄 ~ file: landing-page.js ~ line 44 ~ LandingPage ~ props', { props } )

    const {
        data: {
            sanityLandingPage: {
                seo: { 
                    seoTitle = '', 
                    seoDescription = ''
                },
                defaultLayout = false,
                includeSiteCta = false,
                content = [],
                mainImage = {},
                slug: { current: url = '' }
            }
        }
    } = props
    
    return (
        <LayoutWrapper defaultLayout={defaultLayout}>
            <SEO
                title={seoTitle}
                description={seoDescription}
                image={mainImage}
                url={`/${url}/`} />
            {content && (
                <Stack
                    spacing={[5, 10]}
                    direction='column'>
                    {content.map( ( contentItem, index ) => {
                        // Destructure content values
                        const {
                            _key: key = '',
                            _type: contentType = '',
                            ...rest
                        } = contentItem

                        return (
                            <ContentMap
                                key={key}
                                contentIndex={index}
                                contentType={contentType}
                                {...rest} />
                        )
                    } )}
                </Stack>
            )}
            {includeSiteCta && (
                <Container maxWidth='6xl' my={[20, 40]}>
                    <CallToAction />
                </Container>
            )}
        </LayoutWrapper>
    )
}

export default LandingPage
