/**
 * Nosy Content Gallery
 *
 * @export ContentGallery
 */

import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    SimpleGrid,
    Stack,
    useBreakpointValue,
    useDisclosure,
    useToken
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { PortableText } from '@portabletext/react'
import capitalize from 'capitalize'
import Color from 'color'
import React, { useState } from 'react'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { contentBuilderBodySerializer } from '../../serializers'
import Image from '../image/image'
import Link from '../link/link'

// Gallery type variants

//#region IsolatedGallery
const IsolatedGallery = ( {
    contentIndex = null,
    contentAnchor = {},
    galleryTitle = '',
    galleryImages = [],
    galleryBackgroundColor = {}
} ) => {
    // Get default fallback colors from theme
    const [brandGrayscaleBlack] = useToken( 'colors', ['brand_grayscale.black'] )

    // Destructure color values, and assign fallback
    const { hex: galleryBackgroundColorHex = brandGrayscaleBlack } =
        galleryBackgroundColor
    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( galleryBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Handle modal states
    const { isOpen, onOpen, onClose } = useDisclosure()

    // Handle image selection
    const [media, setMedia] = useState( null )

    // Create dynamic image sizing relative to viewport/device
    const thumbnailSizes = useBreakpointValue( {
        base: 447,
        sm: 703,
        md: 283,
        lg: 326,
        xl: 360
    } )

    const modalImageWidths = useBreakpointValue( {
        base: 447,
        sm: 735,
        md: 960,
        lg: 1120
    } )

    return (
        <React.Fragment>
            <Flex
                id={anchor}
                as='section'
                direction='column'
                width='full'
                minHeight={['460px', null, '600px']}
                height='full'
                px={[0, 4, 6, 8, 12]}
                pt={contentIndex === 0 ? 0 : 12}
                pb={12}
                bg={galleryBackgroundColorHex}
                justifyContent='center'
                alignItems='center'>
                <Container maxWidth='6xl'>
                    <Stack
                        direction='column'
                        spacing={6}
                        maxWidth={['full', null, '4xl', '5xl', '6xl']}
                        width='full'>
                        {galleryTitle && (
                            <Heading
                                as='h1'
                                size='3xl'
                                color={textColor}
                                fontWeight='extrabold'
                                maxWidth='5xl'
                                mx='auto'
                                textAlign='center'
                                lineHeight='1.2'
                                letterSpacing='tight'
                                mb={12}>
                                {galleryTitle}
                            </Heading>
                        )}
                        <SimpleGrid
                            columns={[1, null, 3]}
                            spacing={6}
                            width='full'>
                            {galleryImages &&
                                galleryImages.map( ( image ) => {
                                    // Grab extension from image.asset object
                                    const { extension = '' } = image?.asset

                                    return (
                                        <Image
                                            key={image._key}
                                            onClick={() => {
                                                setMedia( image )
                                                onOpen()
                                            }}
                                            p={extension === 'svg' ? 6 : 0}
                                            imageData={image}
                                            mx='auto'
                                            objectFit={
                                                extension === 'svg'
                                                    ? 'contain'
                                                    : 'cover'
                                            }
                                            width={thumbnailSizes}
                                            height={thumbnailSizes}
                                            _hover={{
                                                cursor: 'pointer'
                                            }} />
                                    )
                                } )}
                        </SimpleGrid>
                    </Stack>
                </Container>
            </Flex>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setMedia( null )
                }}
                motionPreset='scale'
                blockScrollOnMount={false}
                size='6xl'
                isCentered>
                <ModalOverlay
                    backgroundColor={transparentize(
                        'brand_grayscale.darker',
                        0.75
                    )} />
                <ModalContent
                    borderRadius={0}
                    backgroundColor='brand_grayscale.darkest'>
                    <ModalBody p={4}>
                        {media && (
                            <Image
                                imageData={media}
                                // mx='auto'
                                w='full'
                                objectFit={
                                    media?.asset?.extension === 'svg'
                                        ? 'contain'
                                        : 'cover'
                                }
                                // width={1100}
                                width={modalImageWidths}
                                height={800} />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}
//#endregion

//#region GalleryWithContent
const GalleryWithContent = ( {
    contentIndex = null,
    contentAnchor = {},
    galleryTitle = '',
    galleryStrapline = [],
    galleryImages = [],
    galleryBackgroundColor = {},
    galleryButton: {
        buttonLabel = '',
        buttonColor = {},
        customLink = false,
        destinationUrl = '',
        destinationRef = {},
        buttonNewTab = false
    }
} ) => {
    // Get default fallback colors from theme
    const [brandColor, brandGrayscaleBlack] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.black'
    ] )

    // Destructure color values, and assign fallback
    const { hex: galleryBackgroundColorHex = brandGrayscaleBlack } =
        galleryBackgroundColor
    const { hex: buttonColorHex = brandColor } = buttonColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( galleryBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Resolve link destination urls
    const { siteUrl = '' } = useSiteMetadata()

    let destination
    if ( customLink ) {
        destination = destinationUrl
    } else {
        destination = destinationRef.slug
            ? `${siteUrl}/${destinationRef.slug.current}/`
            : '#'
    }

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    // Handle modal states
    const { isOpen, onOpen, onClose } = useDisclosure()
    // Handle image selection
    const [media, setMedia] = useState( null )

    // Create dynamic image sizing relative to viewport/device
    const thumbnailSizes = useBreakpointValue( {
        base: 447,
        sm: 703,
        md: 283,
        lg: 326,
        xl: 360
    } )

    const modalImageWidths = useBreakpointValue( {
        base: 447,
        sm: 735,
        md: 960,
        lg: 1120
    } )

    return (
        <React.Fragment>
            <Flex
                id={anchor}
                as='section'
                direction='column'
                width='full'
                minHeight={['460px', null, '600px']}
                height='full'
                px={[0, 4, 6, 8, 12]}
                pt={contentIndex === 0 ? 0 : 12}
                pb={12}
                bg={galleryBackgroundColorHex}
                justifyContent='center'
                alignItems='center'>
                <Container maxWidth='6xl'>
                    <Stack
                        direction='column'
                        spacing={12}
                        maxWidth={['full', null, '4xl', '5xl', '6xl']}
                        width='full'>
                        <SimpleGrid
                            columns={[1, null, 3]}
                            spacing={6}
                            width='full'>
                            {galleryImages &&
                                galleryImages.map( ( image ) => (
                                    <Image
                                        key={image._key}
                                        onClick={() => {
                                            setMedia( image )
                                            onOpen()
                                        }}
                                        mx='auto'
                                        imageData={image}
                                        objectFit='cover'
                                        width={thumbnailSizes}
                                        height={thumbnailSizes}
                                        _hover={{
                                            cursor: 'pointer'
                                        }} />
                                ) )}
                        </SimpleGrid>
                    </Stack>
                    <Box
                        maxWidth='2xl'
                        mx='auto'
                        px={{
                            base: '6',
                            lg: '8'
                        }}
                        py={{
                            base: '16',
                            sm: '20'
                        }}>
                        {galleryTitle && (
                            <Heading
                                as='h1'
                                size='3xl'
                                color={textColor}
                                fontWeight='extrabold'
                                maxWidth='5xl'
                                lineHeight='1.2'
                                letterSpacing='tight'
                                mb={12}>
                                {galleryTitle}
                            </Heading>
                        )}
                        <Box
                            mt={4}
                            color={textColor}>
                            <PortableText
                                value={galleryStrapline}
                                components={contentBuilderBodySerializer} />
                        </Box>
                        <Button
                            as={Link}
                            to={destination}
                            variant='brand-outline'
                            target={buttonNewTab ? '_blank' : null}
                            size='lg'
                            px={8}
                            fontWeight='bold'
                            fontSize='md'
                            borderColor={buttonColorHex}
                            _hover={{
                                borderColor: Color( buttonColorHex )
                                    .darken( 0.125 )
                                    .hex(),
                                backgroundColor: Color( buttonColorHex )
                                    .darken( 0.125 )
                                    .hex()
                            }}>
                            {buttonLabel}
                        </Button>
                    </Box>
                </Container>
            </Flex>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setMedia( null )
                }}
                motionPreset='scale'
                blockScrollOnMount={false}
                size='6xl'
                isCentered>
                <ModalOverlay
                    backgroundColor={transparentize(
                        'brand_grayscale.darker',
                        0.75
                    )} />
                <ModalContent
                    borderRadius={0}
                    backgroundColor='brand_grayscale.darkest'>
                    <ModalBody p={4}>
                        {media && (
                            <Image
                                imageData={media}
                                // mx='auto'
                                w='full'
                                objectFit={
                                    media?.asset?.extension === 'svg'
                                        ? 'contain'
                                        : 'cover'
                                }
                                // objectFit='cover'
                                // width={1100}
                                // height={800}
                                width={modalImageWidths} />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}
//#endregion

const ContentGallery = ( { galleryType = '', ...rest } ) => {
    // Create lookup map to match hero type to a component
    const galleryTypes = {
        // Isolated Gallery with optional heading
        'isolated-gallery': IsolatedGallery,
        // Gallery with Heading, strapline & button
        'gallery-with-content': GalleryWithContent
    }

    if ( typeof galleryTypes[galleryType] === 'undefined' ) {
        return (
            <Container
                maxWidth='full'
                centerContent>
                <Alert
                    status='error'
                    variant='left-accent'
                    borderRadius='sm'
                    my={10}
                    maxWidth='2xl'>
                    <AlertTitle mr={2}>Gallery Component not found</AlertTitle>
                    <AlertDescription>
                        Component <strong>"{capitalize( galleryType )}"</strong>{' '}
                        not found 😕
                    </AlertDescription>
                </Alert>
            </Container>
        )
        // ... Else create Gallery Component and pass through props
    } else {
        const GalleryComponent = galleryTypes[galleryType]
        return <GalleryComponent {...rest} />
    }
}

export default ContentGallery
