/**
 * Nosy Content Item Accordian
 *
 * @export ContentAccordian
 */
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Container, Flex, Heading, Stack, useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import Color from 'color'
import React from 'react'
import { contentBuilderBodySerializer } from '../../serializers'

const ContentAccordian = ( {
    contentIndex = null,
    contentAnchor = {},
    accordianTitle = null,
    accordianItems = [],
    accordianBackgroundColor = {}
} ) => {
    // Get default fallback colors from theme
    const [brandGrayscaleBlack] = useToken( 'colors', ['brand_grayscale.black'] )

    const { 
        hex: accordianBackgroundColorHex = brandGrayscaleBlack 
    } = accordianBackgroundColor

    // Derive text colour depending on background color lightness/darkness
    const textColor = Color( accordianBackgroundColorHex ).isDark()
        ? 'gray.200'
        : 'gray.800'

    // Destructure contentAnchor ID
    const { current: anchor = '' } = contentAnchor

    return (
        <Flex
            id={anchor}
            as='section'
            direction='column'
            width='full'
            minHeight={['460px', null, '600px']}
            height='full'
            bg={accordianBackgroundColorHex}
            justifyContent='center'
            alignItems='center'
            pt={contentIndex === 0 ? 0 : 12}
            pb={12}>
            <Stack
                direction='column'
                spacing={6}
                maxWidth={['full', null, '4xl', '5xl', '6xl']}
                width='full'>
                {accordianTitle && (
                    <Heading
                        as='h1'
                        size='3xl'
                        color={textColor}
                        fontWeight='extrabold'
                        maxWidth='5xl'
                        mx='auto'
                        textAlign='center'
                        lineHeight='1.2'
                        letterSpacing='tight'
                        mb={12}>
                        {accordianTitle}
                    </Heading>
                )}
            </Stack>
            {accordianItems && 
                <Container maxWidth='4xl'>
                    <Accordion allowToggle>
                        {accordianItems.map( ( accordianItem ) => (
                            <AccordionItem key={accordianItem._key}>
                                <AccordionButton 
                                    _expanded={{ color: 'brand_primary.700' }}
                                    _focus={{ boxShadow: 'none' }}>
                                    <Flex 
                                        justifyContent='space-between' 
                                        alignItems='center' 
                                        width='full'
                                        _hover={{
                                            color: 'brand_secondary.500'
                                        }}>
                                        {accordianItem.accordianItemTitle && (
                                            <Heading 
                                                as='h5' 
                                                fontSize='2xl'
                                                color='gray.100'
                                                py={4}>
                                                {accordianItem.accordianItemTitle}
                                            </Heading>
                                        )}
                                        <AccordionIcon color='brand_secondary.500' />
                                    </Flex>
                                </AccordionButton>
                                
                                <AccordionPanel pt={10}>
                                    {accordianItem.accordianItemContent && (
                                        <PortableText
                                            value={accordianItem.accordianItemContent}
                                            components={contentBuilderBodySerializer} />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                        ) )}
                    </Accordion>
                </Container>
            }
        </Flex>
    )
}

export default ContentAccordian
